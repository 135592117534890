import vector from '../assets/logo.png'
import '../components/components.css'
const Landing = () => {
    return (
       <div className="landing" style={{fontFamily:'Digital Numbers Regular'}}>
           <div className='landing_img'>
           
               <img src={vector} alt="" />
              
           </div>
           <div className="landing_cont">
           
           <p className='landingP'>Website Coming soon</p>
           <br/>
                <p style={{textDecoration : "none"}}><span><a href="https://twitter.com/invctslabs" target="_blank" rel='noreferrer'>X</a> </span>  | <span><a href="mailto:" target="_blank" rel='noreferrer'> Mail</a> </span> | <span><a href="" target="_blank" rel='noreferrer'> Schedule a Call</a> </span>

                </p>
           </div>
       </div>
     );
}

export default Landing;
