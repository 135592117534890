import React from 'react';
import Landing from './components/Landing';
import './App.css';
import Navbar from './components/Navbar';
function App() {

  return (
    <div className="Background">
    <Navbar/>
    <Landing/>


  </div>
  );
}

export default App;
