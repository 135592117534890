
const Navbar = () => {

    return (
        <div className='header'>
        <div className="Navbar">
        <div className="logo">

        {/* <img src={OfflineProtocol} alt="" /> */}
        </div>
        {/* <div className="handles">
        <a href="mailto:gm@offlineprotocol.com" target='_blank' rel='noreferrer'>
        <img src={Mail} alt="" /></a>
        <a href="https://twitter.com/OfflineOracles" target='_blank' rel='noreferrer'>
        <img src={Twitter} alt="" />
        </a>

        </div> */}


        </div>
        </div>
        );
    }

    export default Navbar;
